// extracted by mini-css-extract-plugin
export var PartnerMobileCarousel = "CommunitiesSlider-module--PartnerMobileCarousel--2f52e";
export var PartnersCarouselDesc = "CommunitiesSlider-module--PartnersCarouselDesc--e4a9c";
export var blogArrowContainer = "CommunitiesSlider-module--blogArrowContainer--d1b49";
export var btnDiv = "CommunitiesSlider-module--btnDiv--f9aa8";
export var carousel = "CommunitiesSlider-module--carousel--a83f4";
export var carouselDiv = "CommunitiesSlider-module--carouselDiv--8fc7b";
export var carouselImage = "CommunitiesSlider-module--carouselImage--cd05e";
export var carouselImageDiv = "CommunitiesSlider-module--carouselImageDiv--82186";
export var carouselImg = "CommunitiesSlider-module--carouselImg--86170";
export var carouselImgDivCommunities = "CommunitiesSlider-module--carouselImgDivCommunities--5c66c";
export var carouselMainDiv = "CommunitiesSlider-module--carouselMainDiv--53792";
export var carousel__container = "CommunitiesSlider-module--carousel__container--090bd";
export var carousel__slider = "CommunitiesSlider-module--carousel__slider--54cc0";
export var carousel__sliderTrayWrapHorizontal = "CommunitiesSlider-module--carousel__slider-tray-wrap--horizontal--33cc5";
export var carousel__sliderTrayWrapper = "CommunitiesSlider-module--carousel__slider-tray-wrapper--feb6c";
export var caseStudiesDescription = "CommunitiesSlider-module--caseStudiesDescription--0a193";
export var contactButton = "CommunitiesSlider-module--contactButton--416b7";
export var paragraph = "CommunitiesSlider-module--paragraph--ffcdc";
export var partnersMainSlider = "CommunitiesSlider-module--partnersMainSlider--cae91";
export var subDiv = "CommunitiesSlider-module--subDiv--0465d";