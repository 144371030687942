// extracted by mini-css-extract-plugin
export var awsExpertImg = "CommunityMain-module--awsExpertImg--f7bf7";
export var awsExpertImgDiv = "CommunityMain-module--awsExpertImgDiv--f27d2";
export var awsMainImage = "CommunityMain-module--awsMainImage--348cd";
export var awscommunityawardslogo = "CommunityMain-module--awscommunityawardslogo--1130b";
export var businessImage = "CommunityMain-module--businessImage--4d01c";
export var businessItem = "CommunityMain-module--businessItem--090ed";
export var businessItemImage = "CommunityMain-module--businessItemImage--b1937";
export var businessItemMain = "CommunityMain-module--businessItemMain--c210f";
export var businessMain = "CommunityMain-module--businessMain--96fc6";
export var businessText = "CommunityMain-module--businessText--6df9a";
export var buttonClasses = "CommunityMain-module--buttonClasses--9d0ac";
export var carouselContainer = "CommunityMain-module--carouselContainer--4d9a4";
export var caseChallengeImage = "CommunityMain-module--caseChallengeImage--d21e0";
export var caseTestimonialImage = "CommunityMain-module--caseTestimonialImage--767f2";
export var contactButton = "CommunityMain-module--contactButton--3cd13";
export var detailsBoxLeft = "CommunityMain-module--detailsBoxLeft--371a2";
export var hashicorpcommunityawardslogo = "CommunityMain-module--hashicorpcommunityawardslogo--5ab48";
export var heroBackgroundCircleLeft = "CommunityMain-module--heroBackgroundCircleLeft--b52b1";
export var heroBackgroundInnerCircleLeft = "CommunityMain-module--heroBackgroundInnerCircleLeft--31704";
export var heroWhiteBlurCirce = "CommunityMain-module--heroWhiteBlurCirce--d78b8";
export var includedStudiesImage = "CommunityMain-module--includedStudiesImage--221ec";
export var knowUs = "CommunityMain-module--knowUs--141c0";
export var knowUsImage = "CommunityMain-module--knowUsImage--8cd32";
export var knowUsSubDiv = "CommunityMain-module--knowUsSubDiv--e2f81";
export var knowUsText = "CommunityMain-module--knowUsText--394fa";
export var partners = "CommunityMain-module--partners--c32e8";
export var partnersBenefits = "CommunityMain-module--partnersBenefits--01eae";
export var partnersBenefitsCarousel = "CommunityMain-module--partnersBenefitsCarousel--76cd8";
export var partnersBenefitsInner = "CommunityMain-module--partnersBenefitsInner--263da";
export var partnersBenefitsItem = "CommunityMain-module--partnersBenefitsItem--0a860";
export var partnersBenefitsItemBusiness = "CommunityMain-module--partnersBenefitsItemBusiness--5db43";
export var partnersBenefitsItemBusinessTop = "CommunityMain-module--partnersBenefitsItemBusinessTop--bb926";
export var partnersBenefitsTech = "CommunityMain-module--partnersBenefitsTech--59c86";
export var partnersButtonsContainer = "CommunityMain-module--partnersButtonsContainer--051c4";
export var partnersMainSlider = "CommunityMain-module--partnersMainSlider--89c30";
export var reportDetailsBoxRight = "CommunityMain-module--reportDetailsBoxRight--8c37b";
export var singleBenefitTextBox = "CommunityMain-module--singleBenefitTextBox--77281";
export var singleCaseChallenge = "CommunityMain-module--singleCaseChallenge--40052";
export var singleCaseContentBlock = "CommunityMain-module--singleCaseContentBlock--c6458";
export var singleCaseImageBox1 = "CommunityMain-module--singleCaseImageBox1--d55bb";
export var singleCaseSolution = "CommunityMain-module--singleCaseSolution--5f763";
export var singleCaseTestimonial = "CommunityMain-module--singleCaseTestimonial--c7872";
export var singleCaseTextBox = "CommunityMain-module--singleCaseTextBox--bba50";
export var singleReportDetails = "CommunityMain-module--singleReportDetails--cf4c5";
export var testimonialContent = "CommunityMain-module--testimonialContent--a74cc";